<script setup>
    import JetButton from '@jetDS/components/JetButton.vue';
    import JetUploadBox from '@jetDS/components/JetUploadBox.vue';

    const props = defineProps({
        uploadFileType: {
            type: String,
            default: '.jpeg,.jpg,.png,.heic',
        },
        uploadLimit: {
            type: Number,
            default: 3,
        },
        uploadSizeLimit: {
            type: Number,
            default: 5 * 1024 * 1024,
        },
        tipText: {
            type: String,
            default: 'Dimensione massima singolo file: 5 MB',
        },
        buttonSize: {
            type: String,
            default: 'large',
        },
        buttonText: {
            type: String,
            default: 'Carica ricevuta',
        },
        buttonFullWidth: {
            type: Boolean,
            default: true,
        },
        showFileList: {
            type: Boolean,
            default: true,
        },
        submitFunction: {
            type: Function,
            default: null,
        },
        // Pass this prop to display an existing file list
        // File list should be array of objects {uid: string, name: string}
        // Actual File deletion has to be handled by parent
        existingFiles: {
            type: Array,
            default: () => [],
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        enableDrag: {
            type: Boolean,
            default: false,
        },
    });

    const emit = defineEmits([
        'file-uploaded',
        'file-deleted',
        'file-chosen',
        'in-progress-counter',
        'upload-in-progress',
        'local-file-add',
        'local-file-remove',
    ]);

    const {$api} = inject('jet');

    const inProgressCounter = ref(0);
    watch(
        () => inProgressCounter.value,
        () => {
            emit('in-progress-counter', inProgressCounter.value);
        },
    );

    const jetUploadBoxRef = ref(null);
    const fileList = ref([]);
    const ignoreList = ref([]);
    const uidIdMap = reactive({});

    function doSubmit(data) {
        inProgressCounter.value++;

        emit('file-chosen', data);

        $api.uploadedFiles
            .create({
                file: data.file,
                types: props.uploadFileType.replaceAll('.', ''),
                max_size: props.uploadSizeLimit,
            })
            .then(response => {
                const uploadedFileId = response.data.id;
                uidIdMap[data.file.uid] = uploadedFileId;
                emit('file-uploaded', uploadedFileId, data.file.uid);
            })
            .catch(() => {
                if (jetUploadBoxRef.value) {
                    jetUploadBoxRef.value.handleRemove(data.file);
                }
            })
            .finally(() => {
                inProgressCounter.value--;
                emit('upload-in-progress', false);
            })
            .catch($api.end);
    }

    function handleRemove(file) {
        const uploadedFileId = uidIdMap[file.uid];
        const ignoreDeleteOnExistingFiles = ignoreList.value.includes(uploadedFileId);
        if (ignoreDeleteOnExistingFiles) {
            emit('file-deleted', uploadedFileId, file.uid);
            return null;
        }

        if (uploadedFileId) {
            inProgressCounter.value++;
            $api.uploadedFiles
                .delete(uploadedFileId)
                .then(() => {
                    delete uidIdMap[file.uid];
                    emit('file-deleted', uploadedFileId);
                })
                .finally(() => {
                    inProgressCounter.value--;
                })
                .catch($api.end);
        }
    }

    function onUploadInProgress(uploadInProgress) {
        emit('upload-in-progress', uploadInProgress);
    }

    watch(
        () => props.existingFiles,
        existingFileList => {
            if (!existingFileList) {
                return;
            }

            fileList.value = existingFileList;
            ignoreList.value = existingFileList.map(file => file.uid);

            existingFileList.forEach(file => {
                uidIdMap[file.uid] = file.uid;
            });
        },
        {immediate: true},
    );

    defineExpose({clearFiles: () => jetUploadBoxRef.value.clearFiles(), fileList});
</script>

<template>
    <JetUploadBox
        ref="jetUploadBoxRef"
        v-model:file-list="fileList"
        class="upload-box-displayer"
        :class="{'drag-disabled': disabled && enableDrag}"
        :accept="uploadFileType"
        :full-width="buttonFullWidth"
        multiple
        :drag="enableDrag"
        :http-request="submitFunction ?? doSubmit"
        :limit="uploadLimit"
        :on-remove="handleRemove"
        :show-file-list="showFileList"
        :disabled="disabled"
        @upload-in-progress="onUploadInProgress">
        <template v-if="enableDrag">
            <div class="upload-box-displayer__tip mt-1 light sm">
                Trascina i file qui o
                <strong>clicca per caricarli</strong>
            </div>
        </template>
        <template v-else>
            <JetButton
                :full-width="buttonFullWidth"
                :size="buttonSize"
                icon-style="regular"
                icon-name="paperclip"
                type="primary"
                plain
                :disabled="disabled || fileList.length >= uploadLimit">
                {{ buttonText }}
            </JetButton>
        </template>
        <template #tip>
            <div class="upload-box-displayer__tip mt-1 light sm">
                {{ tipText }}
            </div>
        </template>
    </JetUploadBox>
</template>

<style scoped lang="scss">
    .upload-box-displayer__tip {
        color: var(--jet-text-color-placeholder);
    }

    .drag-disabled {
        :deep(.el-upload-dragger) {
            border-color: var(--el-color-primary-light-9);
        }
    }
</style>
