<script setup>
    import {ONBOARDING_TASK_CODENAME, ONBOARDING_TASK_STATUS} from '@jetCommon/constants/onboarding.js';
    import {useOnboarding} from '@/features/onboarding/composables/onboarding.js';

    import OnboardingTaskItem from '@/features/onboarding/components/tasks/base/OnboardingTaskItem.vue';
    import PayrollDatesForm from '@jetCommon/components/onboarding/PayrollDatesForm.vue';

    const {fetchAll, taskByCodename} = useOnboarding();
    const task = computed(() => taskByCodename.value[ONBOARDING_TASK_CODENAME.PAYROLL_DATES]);
    const taskIsToDo = computed(() => task.value.status === ONBOARDING_TASK_STATUS.TODO);

    const formRef = ref(null);

    const actions = computed(() => {
        return [
            {
                main: true,
                text: taskIsToDo.value ? 'Compila' : 'Visualizza',
                dialog: {
                    title: 'Giorno di paga, santo patrono e gestione presenze assenze',
                    name: 'main',
                    confirm_button_text: 'Salva',
                    confirm_callback() {
                        formRef.value.submit();
                    },
                    show_confirm_button: taskIsToDo.value,
                },
            },
        ];
    });
</script>

<template>
    <OnboardingTaskItem
        :actions="actions"
        description="Imposta le informazioni necessarie per l'elaborazione dei cedolini."
        :codename="ONBOARDING_TASK_CODENAME.PAYROLL_DATES"
        title="Giorno di paga, santo patrono e gestione presenze assenze">
        <template #dialogMainBody>
            <PayrollDatesForm ref="formRef" :read-only="!taskIsToDo" :task="task" @submitted="fetchAll()" />
        </template>
    </OnboardingTaskItem>
</template>
