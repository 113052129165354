import {dailyClockingSurrogateKey} from '@jetCommon/helpers/clocking';
import ClockingsApiCommon from '@jetCommon/api/clocking.js';

export default class ClockingApi extends ClockingsApiCommon {
    static resourceName = 'daily_clockings';

    yearMonthChoices(companyId) {
        return this.apiGet('year_month_choices/', {params: {company: companyId}});
    }

    ignoreAnomalies(companyId, date, employeeId, ignoreFlag) {
        return this.apiPost(`${dailyClockingSurrogateKey(employeeId, date)}/ignore_anomalies/`, {
            company: companyId,
            ignore: ignoreFlag,
        });
    }
}
